import React, { useState } from "react";
import { useFetchMenulistQuery, useAddMenuMutation, useUpdateMenuMutation } from "../../redux/reducers/menulistApiSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const SettingsPage = () => {
  const { data: menuList, isLoading } = useFetchMenulistQuery();
  const [addMenuItem] = useAddMenuMutation();
  const [updateMenuItem] = useUpdateMenuMutation();
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState("");
  const [initialValues, setInitialValues] = useState({ key: "", value: "" });

  // Yup schema for validation
  const validationSchema = Yup.object({
    key: Yup.string().required("Key is required"),
    value: Yup.string().required("Value is required"),
  });

  const handleAdd = () => {
    setIsEditing(false);
    setInitialValues({ key: "", value: "" });
    setShowModal(true);
  };

  const handleEdit = (item) => {
    setIsEditing(true);
    setInitialValues({ key: item.key, value: item.value });
    setId(item._id);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setId("");
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (isEditing) {
        await updateMenuItem({id, menu: values});
      } else {
        await addMenuItem(values);
      }
      resetForm();
      setShowModal(false);
    } catch (error) {
      console.error("Failed to submit form", error);
    }
  };

  return (
    <div className={`container mt-4 ${showModal ? "blur-background" : ""}`}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Settings</h1>
        <button className="btn btn-primary" onClick={handleAdd}>Add</button>
      </div>

      {isLoading ? (
        <div className="text-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {menuList?.map((setting, index) => (
              <tr key={index}>
                <td>{setting.key}</td>
                <td>{setting.value}</td>
                <td>
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleEdit(setting)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Centered Modal for Add/Edit Form */}
      {showModal && (
        <div className="modal show d-block fade" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{isEditing ? "Edit Key-Value Pair" : "Add Key-Value Pair"}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="key">Key</label>
                        <Field
                          type="text"
                          name="key"
                          className={`form-control ${errors.key && touched.key ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          name="key"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="value">Value</label>
                        <Field
                          type="text"
                          name="value"
                          className={`form-control ${errors.value && touched.value ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          name="value"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsPage;