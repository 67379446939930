import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const menulistAPI = createApi({
    reducerPath: "menulistAPI",
    baseQuery: fetchBaseQuery({ baseUrl: "https://deviceapi.rfidpumpoptr.co.in/menulist" }),
    tagTypes: ["menulist"],
    endpoints: (builder) => ({
        fetchMenulist: builder.query({
            query: () => ({
                url: "/",
                method: "GET",
                headers: {
                    ['x-auth-token']: "NextAuthTokenKey " +  JSON.parse(localStorage.getItem('user'))?.token
                }
            }),
            providesTags: ["menulist"]
        }),
        fetchMenubyId: builder.query({
            query: (id) => ({
                url: `/${id}`,
                method: "GET",
                headers: {
                    ['x-auth-token']: "NextAuthTokenKey " +  JSON.parse(localStorage.getItem('user'))?.token
                  }
            }),
        }),
        addMenu: builder.mutation({
            query: (menu) => ({
                url: "/",
                method: "POST",
                body: menu,
                headers: {
                    ['x-auth-token']: "NextAuthTokenKey " +  JSON.parse(localStorage.getItem('user'))?.token
                  }
            }),
            invalidatesTags: ["menulist"]
        }),
        updateMenu: builder.mutation({
            query: ({ id, menu }) => ({
                url: `/${id}`,
                method: "PATCH",
                body: menu,
                headers: {
                    ['x-auth-token']: "NextAuthTokenKey " +  JSON.parse(localStorage.getItem('user'))?.token
                  }
            }),
            invalidatesTags: ["menulist"]
        }),
        deleteMenu: builder.mutation({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["menulist"]
        }),
    }),
})

export const {
    useFetchMenulistQuery,
    useFetchMenubyIdQuery,
    useAddMenuMutation,
    useUpdateMenuMutation,
    useDeleteMenuMutation
} = menulistAPI;
