import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoginMutation } from "../../redux/reducers/userApiSlice"; // Assuming the API slice is in this path
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const SignIn = () => {
  const [loginPost, { data: user, isLoading, isError, error }] = useLoginMutation();
  const { login } = useAuth();
  const navigate = useNavigate(); // To navigate after successful login

  // Formik form handling
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        let result = await loginPost(values).unwrap(); // Make API call with form data
        if (result) {
          let data = structuredClone(result);
          delete data.password;
          localStorage.setItem('user', JSON.stringify(data));
          login(data.token)
          navigate("/home"); // Navigate to dashboard on successful login
        }
      } catch (err) {
        console.error("Failed to login: ", err);
      }
    },
  });

  return (
    <div className="container vh-100 d-flex justify-content-center align-items-center">
      <div className="card shadow-lg p-4" style={{ width: "100%", maxWidth: "400px" }}>
        <h3 className="text-center mb-4">Sign In</h3>

        <form onSubmit={formik.handleSubmit}>
          {/* Email Field */}
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
              id="email"
              placeholder="Enter your email"
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.email}</div>
            ) : null}
          </div>

          {/* Password Field */}
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className={`form-control ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
              id="password"
              placeholder="Enter your password"
              {...formik.getFieldProps('password')}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback">{formik.errors.password}</div>
            ) : null}
          </div>

          {/* Submit Button */}
          <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
            {isLoading ? "Signing In..." : "Sign In"}
          </button>

          {/* Error Message */}
          {isError && <div className="alert alert-danger mt-3">{error?.data?.message || "Failed to sign in"}</div>}
        </form>

        {/* Forgot Password Link */}
        <p className="text-center mt-3">
          <a href="/forgot-password" className="text-decoration-none">
            Forgot password?
          </a>
        </p>

        {/* Sign Up Link */}
        {/* <p className="text-center mt-2">
          Don't have an account? <a href="/signup">Sign up here</a>.
        </p> */}
      </div>
    </div>
  );
};

export default SignIn;