import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const logApi = createApi({
  reducerPath: "logApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://deviceapi.rfidpumpoptr.co.in/motor" }),
  endpoints: (builder) => ({
    fetchUserLogs: builder.query({
      query: ({ userId, date }) => ({
        url: `/getlogs/${userId}`, // Adjust the endpoint based on your setup
        method: "POST",
        body: { date },
        headers: {
          ['x-auth-token']: "NextAuthTokenKey " +  JSON.parse(localStorage.getItem('user'))?.token
        }
      }),
      transformResponse: (response) => {
        // Transform the response if necessary
        return response; // You can modify this based on your needs
      },
      keepUnusedDataFor: 0,
    }),
    fetchMotorStatus: builder.query({
      query: () => `/getMotorStatus`,
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useFetchUserLogsQuery, useFetchMotorStatusQuery } = logApi;
