// src/components/ProtectedRoute.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = () => {
  const { token } = useAuth();

  const isTokenExpired = (jwtToken) => {
    if (!jwtToken) return true;
    const { exp } = jwtDecode(jwtToken);
    return Date.now() >= exp * 1000;
  };

  return token && !isTokenExpired(token) ? <Outlet /> : <Navigate to="/signin" replace />;
};

export default ProtectedRoute;