// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));

  // Function to check if token is expired
  const isTokenExpired = (jwtToken) => {
    if (!jwtToken) return true;
    const { exp } = jwtDecode(jwtToken);
    return Date.now() >= exp * 1000;
  };

  // Login function with expiration check
  const login = (jwtToken) => {
    const { exp } = jwtDecode(jwtToken);
    const expiresIn = exp * 1000 - Date.now();

    // Set a timer to log out when the token expires
    setTimeout(logout, expiresIn);

    localStorage.setItem("token", jwtToken);
    setToken(jwtToken);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setToken(null);
    navigate("/signin");
  };

  // Check token on app load
  useEffect(() => {
    if (isTokenExpired(token)) {
      logout();
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);