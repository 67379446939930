import "./App.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignIn from "./components/Authentication/SignIn";
import SignUp from "./components/Authentication/SignUp";
import ForgotPassword from "./components/Authentication/ForgotPasssword";
import UserList from "./components/Users/UserList";
import Navbar from "./components/Layout/Navbar";
import Content from "./components/Layout/Home";
import UserForm from "./components/Users/UserForm";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import SettingsPage from "./components/Settings/SettingsList";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  // Specify the routes where the Navbar should be visible
  const showNavbarOnPaths = [
    "/home",
    "/users",
    "/settings",
    "/add-user",
    `/edit-user/:id`,
  ]; // User pages

  const shouldShowNavbar = showNavbarOnPaths.some(
    (path) => location.pathname.startsWith(path.split(":")[0]) // Handle dynamic paths like /edit-user/:id
  );

  useEffect(() => {
    if (localStorage.getItem("user")) {
      try {
        const data = JSON.parse(localStorage.getItem("user"));
        if (data._id) {
          setUser(data);
          navigate("/home");
        } else {
          navigate("/signin");
        }
      } catch (error) {
        // navigate('/signin');
      }
    } else {
      // navigate('/signin');
    }
  }, [localStorage.getItem("user")]);

  return (
    <div className="App">
      <AuthProvider>
        {/* Conditionally render the Navbar based on current path */}
        {shouldShowNavbar && <Navbar user={user} />}
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/home" element={<Content />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/add-user" element={<UserForm />} />
            <Route path="/edit-user/:id" element={<UserForm />} />
          </Route>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </AuthProvider>
    </div>
  );
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
};

export default AppWrapper;
