import { configureStore } from "@reduxjs/toolkit";
import { userApi } from "../reducers/userApiSlice"; // Import the userApi slice
import { logApi } from "../reducers/logApiSlice";
import { menulistAPI } from "../reducers/menulistApiSlice";

// Create and configure the store
const store = configureStore({
  reducer: {
    // Add the RTK Query userApi reducer
    [userApi.reducerPath]: userApi.reducer,
    [logApi.reducerPath]: logApi.reducer,
    [menulistAPI.reducerPath]: menulistAPI.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling, and other features of RTK Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userApi.middleware,
      logApi.middleware,
      menulistAPI.middleware,
    ]),
});

export default store;
